import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {
  activeRequest: number = 0
  _newToken: string;
  constructor(private authService: AuthService) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

       this._newToken = localStorage.getItem('token');
       
      if (this.authService.timeOver && this._newToken && this.activeRequest > 0) {
        this.authService.timeOver = false;
        this.activeRequest = 0;
        this.authService.refreshToken(this._newToken).subscribe(
          data => {
            localStorage.removeItem('token');
            localStorage.setItem('token', data.token)
            this._newToken = data.token
            this.authService.timeOutToRefresh();
            this.authService.checkExpiryToken();
            this.authService.setTokenSessionStorage();
            
          }
        );
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this._newToken}`
          }
        });
      } else if (this._newToken != null) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this._newToken}`
          }
        });
      }
    
      
      if(this.authService.timeOver){
        const reqUrl = req.url.split("/")
          const reqLastPath = reqUrl[reqUrl.length - 1]
          if(reqLastPath != 'checkStatus'){
            this.activeRequest++
          }
      
     }
     return next.handle(req);

     
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-resseting-pass',
  templateUrl: './resseting-pass.component.html',
  styleUrls: ['./resseting-pass.component.scss']
})
export class RessetingPassComponent implements OnInit {
  public emailInput!:FormGroup;
  public submitted: boolean = false;

  //Para los alerts
  public errorAlert: boolean = false
  public errorMessage : string;
  public status : string

  constructor( private formBuilder: FormBuilder, private userService : UserService, private router : Router) {
    this.emailInput = this.formBuilder.group({
      email:["", [Validators.required, Validators.email]],
     
    })
   }

  ngOnInit(): void {
  }
  public onSubmit(): void {
    this.submitted = true;
    if(this.emailInput.valid){
      this.errorAlert = false
      const data = {
        email: this.emailInput.get('email')?.value,
      }
      this.userService.resetPasswordEmail(data.email).subscribe({
        complete: () => {
          this.router.navigate(['resetting/check-email'] , { queryParams: { email: data.email }})
        },
        error: (err) => {
          if (err.error.error == 'Reset already requested.'){
            this.errorMessage = 'Ya se ha solicitado un reestablecimiento de contraseña con este email.'
          }else if (err.error.error == 'User not exists.'){
            this. errorMessage = 'Este usuario no existe.'
          }
          this.status = 'ko'
          this.errorAlert = true
        },
        next: (res) => {
        }
      })
      this.submitted = false;
      this.emailInput.reset();
    }
}

}

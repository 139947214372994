import { BorrowerService } from 'src/app/core/services/borrower/borrower.service';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class UpdateStatus{
  

    constructor( private borrowerService: BorrowerService ){
     
    }
    changeStatus(data?: any){
    this.borrowerService.updateStatusBorrower(localStorage.getItem('idBorrower'), data).subscribe(
      (res) => {
        
      }
      )
   }
   
}



import { PdfRenderComponent } from './components/pdf-render/pdf-render.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IbanPanelComponent } from './layout/iban-panel/iban-panel.component';
import { HeaderAmortizationComponent } from './layout/header-amortization/header-amortization.component';
import { HeaderLogginInComponent } from './layout/header-loggin-in/header-loggin-in.component';
import { MaterialModule } from './material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent, FooterLandingComponent, HeaderLandingComponent, HeaderAffiliateComponent, HeaderFinanceComponent } from './layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HeaderAboutComponent } from './layout/header-about/header-about.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from 'src/app/core/services/guards/auth.guard';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { SpinnerInterceptor } from '../core/interceptors/spinner.interceptor';
import { ToolbarAlertsComponent } from './components/toolbar-alerts/toolbar-alerts.component';
import { RecaptchaFormsModule, RecaptchaModule,RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { OrderByPipe } from './pipes/order-by.pipe';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';


@NgModule({
  declarations: [
    LoginFormComponent,
    HeaderLandingComponent,
    FooterLandingComponent,
    HeaderAffiliateComponent,
    HeaderFinanceComponent,
    HeaderAboutComponent,
    ContactFormComponent,
    HeaderLogginInComponent,
    HeaderAmortizationComponent,
    IbanPanelComponent,
    SpinnerComponent,
    UploadFilesComponent,
    ToolbarAlertsComponent,
    OrderByPipe,
    PdfRenderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    NgxSkeletonLoaderModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,

    

  ],
  exports: [
    MaterialModule,
    HeaderLandingComponent,
    FooterLandingComponent,
    HeaderAffiliateComponent,
    LoginFormComponent,
    HeaderFinanceComponent,
    HeaderAboutComponent,
    ContactFormComponent,
    HeaderLogginInComponent,
    HeaderAmortizationComponent,
    IbanPanelComponent,
    SpinnerComponent,
    UploadFilesComponent,
    ToolbarAlertsComponent,
    OrderByPipe,
    PdfRenderComponent,
    NgxSkeletonLoaderModule,
    NgxZendeskWebwidgetModule
  ],
  providers: [
    {
      provide:  RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
   AuthGuard,
   { 
    provide: HTTP_INTERCEPTORS,
    useClass: SpinnerInterceptor,
    multi: true 
  },
  
  ]
    
})
export class SharedModule { }

import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserDataService } from 'src/app/core/services/user/userData.service';

@Component({
  selector: 'app-footer-landing',
  templateUrl: './footer-landing.component.html',
  styleUrls: ['./footer-landing.component.scss']
})
export class FooterLandingComponent implements OnInit {
  public visible: boolean = false;
  breakpoint: number = 768;
  public name: string;
  public userType : string;
  public afilliateType: boolean = false;
  public borrowerType:boolean = false;
  public landingType:boolean = false;

  @Input() hiddenText: boolean = false;
  @Input() hiddenHeader: boolean;
  
  constructor( private auth: AuthService, private userDataService: UserDataService) { }

  ngOnInit(): void {
    this.userType = localStorage.getItem('userType')
    const w = window.innerWidth;
    if (w >= this.breakpoint) {
      this.visible = true;
    } else {
      this.visible = false;
    }

    this.userDataService.getUserType().subscribe(
      res => {
        res ? this.userType = res : this.userType = '';
        if(this.userType === 'afiliado'){
          this.afilliateType = true;
          this.borrowerType = false;
        } else if (this.userType === 'prestatario'){
          this.borrowerType = true;
          this.afilliateType = false;
        } else {
          this.landingType = true
          this.borrowerType = false;
          this.afilliateType = false;
        }
      }
    )      


  }

  onResize(event:any) {
    const w = event.target.innerWidth;
    if (w >= this.breakpoint) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { ExpansionPanel } from 'src/app/core/models/expansion-panel';

@Component({
  selector: 'app-faq-about',
  templateUrl: './faq-about.component.html',
  styleUrls: ['./faq-about.component.scss']
})
export class FaqAboutComponent implements OnInit {
  public expansionPanel: ExpansionPanel []=[
    {
      title:"¿Qué es Confia?",
      description: "Es un conjunto de soluciones de financiación y pagos adaptadas al comercio físico, al comercio digital y a particulares mediante productos sencillos y transparentes.",
    },
    {
      title:" ¿Quién está detrás de Confia?",
    description:"Confia es una marca registrada por Unión Financiera Asturiana S.A.E.F.C en funcionamiento desde 1985.",
    },
    {
      title:" ¿Qué criterios seguís para aprobar los créditos?",
    description:"En función de un sistema de scoring. Es decir, el conjunto de parámetros que evalúan la solvencia de una persona. Esta categorización da información sobre la probabilidad de que el prestatario devuelva el préstamo",
    },
    {
      title:" ¿Cuánto cuesta registrase como comercio afiliado?",
    description:"Nada. Escribe un email a afiliacion.confia@confia.com.es indicando el producto o servicio que ofreces. Una persona de nuestro equipo se pondrá en contacto contigo para asesorarte.",
    },
    {
      title:"¿Cumple Confia con algún tipo de regulación?",
    description:"Si, Confía es una marca comercial de un Establecimiento Financiero de Credito, E.F.C, regulado por el Banco de España.",
    },
    {
      title:"Si soy un cliente con créditos concedidos por Confia, ¿cómo puedo acceder a mis datos y a los diferentes créditos?",
    description:"Cuando Confia da de alta tu financiación, recibes un email automático con tu usuario (el email) y la contraseña, que luego podrás modificar.",
    }
    
    ]
  constructor() { }

  ngOnInit(): void {
  }

}

<div class="reg_container">
  <div class="Page_container">
<app-header-finance></app-header-finance>
<div class="container"></div>
<div class="page-container">
  <div class="img-container">
    <h1 class="title-big">
      Para nosotros no hay más puntos de vista que el tuyo,
    </h1>
    <span class="color-inversor"> y para ti el de tus clientes</span>
  </div>
  <div class="text-container">
    <div class="text-left">
      <p class="text-body">
        No pierdas más ventas por no tener una solución de financiación rápida.
        CONFIA te da respuesta online y te abona las operaciones en un máximo de
        24 horas.
      </p>
    </div>
    <div class="text-right">
      <p  class="text-body">
        Pide financiación para tus clientes desde cualquier dispositivo, en
        cualquier momento, con aprobación inmediata y sin papeles.
      </p>
      <button
        mat-flat-button
        color="primary"
        [routerLink]="'/financiar/registrate'"
        routerLinkActive="registrate"
      >
        ¿EMPEZAMOS?
      </button>
      <app-contact-form-shared></app-contact-form-shared>
    </div>
  </div>
</div>
</div>
</div>

import { Component } from '@angular/core';
import { HowAboutCard } from 'src/app/core/models/how-about';

@Component({
  selector: 'app-confia-ten',
  templateUrl: './confia-ten.component.html',
  styleUrls: ['./confia-ten.component.scss'],
})
export class ConfiaTenComponent {
  public financeCard!: HowAboutCard[];
  constructor() {
    this.financeCard = [
      {
        image: {
          src: '../../../../assets/imgConfia/img/confiaimg/ICO-Entrada-Confia-01.svg',
          alt: 'entry_icon',
        },
        title: 'Entrada en tienda',
        startText: '10',
        endText: '=100',
      },
      {
        image: {
          src: '../../../../assets/imgConfia/img/confiaimg/ICO-Cuota-Confia-02.svg',
          alt: 'share_icon',
        },
        title: 'Cuota mensual',
        text: '100',
      },
      {
        image: {
          src: '../../../../assets/imgConfia/img/confiaimg/ICO-Euro-Confia-01.svg',
          alt: 'euro_icon',
        },
        title: 'Importe a financiar',
        text: '900',
      },
    ];
  }
}

import { ErrorInterceptorService } from './../../../core/interceptors/error-interceptor.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';
import { AffiliateService } from 'src/app/core/services/affiliate/affiliate.service';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { getFase } from 'src/app/core/extensions/status.extensions';
import { UserDataService } from 'src/app/core/services/user/userData.service';
import { concatMap, of } from 'rxjs';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  public registerForm!: FormGroup;
  public submitted: boolean = false;
  public _hideNav: boolean
  public userType: string;
  public affiliatePath: string[];
  public creditList: any[];
  public activeCredit : any;
  public error: any;
  public userName: string;
  public emailToLogin: any;
  @Input() hiddenText: boolean = false;
  @Input() set hiddenNav(val: boolean) {
    this._hideNav = val
  }

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private affiliateService: AffiliateService,
    public SpinnerService: SpinnerService,
    private errorService: ErrorInterceptorService,
    private userDataService: UserDataService
    ) {

  }

  ngOnInit(): void {
    
    this.registerForm = this.formBuilder.group({
      _username: ["", [Validators.required, Validators.email]],
      _password: ["", [Validators.required, /* Validators.pattern(allRegex.regexPassword) */]]
    })
  }


  public onSubmit(): void {
    const formData = new FormData();
    this.submitted = true;
    if (this.registerForm.valid) {
      formData.append('_username', this.registerForm.get('_username').value),
        formData.append('_password', this.registerForm.get('_password').value),
        this.authService.signIn(formData).subscribe(
          data => {
            this.emailToLogin = { email: this.registerForm.get('_username').value }
            localStorage.setItem('email', this.registerForm.get('_username').value)
            this.submitted = false;
            this.setSession(data.token);
            this.userData();
        },
           err =>  {
            if(err.error.message == "Invalid credentials."){
              this.error = 'Usuario y/o contraseña no válidos.'
              /* this.registerForm.get('_username').setErrors({credendialsError:'Usuario y/o contraseña no válidos.'})
              this.registerForm.get('_password').setErrors({credendialsError:'Usuario y/o contraseña no válidos.'}) */
            }
            
          }
        )
    }
  }

  userData() {
    this.userDataService.setUserType('');
    this.userDataService.setNameSession('');
    this.userService.getUserByEmail(this.emailToLogin).pipe(
      concatMap((res) => {
        localStorage.setItem('userType', res["hydra:member"][0].tipo)
        this.userDataService.setUserType(res["hydra:member"][0].tipo)
        const userId = res['hydra:member'][0].id;
        localStorage.setItem('userId', userId);
        localStorage.setItem('userName', res['hydra:member'][0].firstname)
    
        this.userType = localStorage.getItem('userType')
        if (this.userType === 'prestatario') {
          this.userDataService.setNameSession(res['hydra:member'][0].firstname);
          if (res['hydra:member'][0].afiliado){
            this.affiliatePath = res["hydra:member"][0].afiliado?.split("/");
            const affiliateId = this.affiliatePath?.[this.affiliatePath?.length - 1];
            localStorage.setItem('affiliateId', affiliateId);
            return this.userService.getCreditList(userId)
                    
            }else{
              this.router.navigate(['/prestatario/inactivo'])
              return of(null);
            }
        } else {
          this.affiliatePath = res["hydra:member"][0].afiliado?.split("/");
          const affiliateId = this.affiliatePath?.[this.affiliatePath?.length - 1];
          localStorage.setItem('affiliateId', affiliateId);
          return of(null);
        }
      }),
      concatMap((res) => {
        if(res) {
          this.creditList = res["hydra:member"];
          this.activeCredit = this.creditList.find(item => getFase(item.estadoCd) == 'FC' || getFase(item.estadoCd) == 'FA'|| getFase(item.estadoCd) == 'FS' || getFase(item.estadoCd) == 'FF' ||
          item.estadoCd == 'EAM' || item.estadoCd == 'RET' || item.estadoCd == 'FAL') 
        }
        return this.affiliateService.getAffiliate(localStorage.getItem('affiliateId'))
      })
      ).subscribe((data) => {
              localStorage.setItem('financedRate',data.financedRate)
              if(this.userType != 'prestatario'){
                 this.userDataService.setNameSession(data.corporationName) 
              }
              const datosEmpresa = data!.datosEmpresa;
              const businessPath = data.datosEmpresa?.split("/");
              const businessId = businessPath?.[businessPath?.length - 1];
              this.affiliateService.businessData(businessId).subscribe(res => {                
                localStorage.setItem('transfers', JSON.stringify(res.transactionsEnabled))
              })
              const userPath = data.associatedInvestor?.split('/');
              const userId = userPath?.[userPath?.length - 1];
              const salesAgentPath = data.zankSalesAgent?.split("/");
              const agentId = salesAgentPath?.[salesAgentPath?.length - 1];
              const reportsEnabled = data.reports;
              const linksEnbled = data.links;
              const simuladorEnabled = data.simulador;
              const usuariosEnabled = data.usuarios;
              const cobrosEnabled = data.cobrosEnabled;
              localStorage.setItem('businessId', businessId);
              localStorage.setItem('estadoCd', data.estadoCd);
              localStorage.setItem('corporationName', data.corporationName)
              localStorage.setItem('datosEmpresa', data.datosEmpresa)
              localStorage.setItem('associatedInvestor', userId);
              localStorage.setItem('salesAgentId', agentId)
              localStorage.setItem('inversores', data.usuarios)
              localStorage.setItem('cobrosEnabled', cobrosEnabled)
              localStorage.setItem('reports', reportsEnabled)
              localStorage.setItem('links', linksEnbled)
              localStorage.setItem('simulador', simuladorEnabled)
              localStorage.setItem('usuarios', usuariosEnabled)
              if (this.userType == 'prestatario'){
                if (this.activeCredit) {
                  this.router.navigate(['/prestatario'], { queryParams: { id: this.activeCredit.id } })     
                } else {
                  this.router.navigate(['/prestatario/inactivo'])                
                }
              }else{
                this.router.navigate(['/afiliado']);
              }
            }, err => {
        /* console.log(err) */
      });
    
  }

  setSession(token: string) {
    if (token != '')
    this.authService.setSessionLogged(token);
    this.authService.setTokenSessionStorage();
    this.authService.timeOutToRefresh();
  }

  logout() {
    return this.authService.logout();
  }

  outToken() {
    return this.logout();
  }

}

<div class="reg_container">
    <div class="Page_container">
        <img src="../../../../assets/img/logo azul.png" alt="logo_confia">
        <mat-divider></mat-divider>
        <div class="reg_content">
            <div class="columns">
                <h2>Verifica tu email</h2>
                <div class="forms_container"> 
                    <div class="email-title">
                        <p>Verifica tu email</p>
                    </div>                 
                    <div class="email-body">
                        <p>
                            Se ha enviado un correo electrónico a tu cuenta de correo {{userEmail}}. Contiene un enlace de activación al que debes acceder para restablecer tu contraseña. Nota: Solo se puede solicitar una nueva contraseña cada 24 horas. Si no recibes un correo electrónico, comprueba tu carpeta de correo no deseado o inténtalo de nuevo.
                        </p>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>


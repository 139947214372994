<div class="reg_container">
  <div class="Page_container">
    <app-header-about></app-header-about>
    <div class="reg_content">
      <div class="title_content">
        <h1 class="title-big">fácil de explicar, <br /> fácil de financiar</h1>
      </div>
    </div>
    <div class="content-container">
      <h2 class="faq-title">Sobre Confia</h2>
      <div class="accordion-content">
        <mat-accordion >
          <mat-expansion-panel *ngFor="let item of expansionPanel">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ item.title }}</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-panel-description
              >{{ item.description }}
            </mat-panel-description>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>

<div 
[ngClass]="{
  'single_container': !ibanCHB,
  'single_cont':ibanCHB
}">
    <div class="title_header">
      <h4>CUENTA BANCARIA</h4>
      <button 
        mat-icon-button
        aria-label="Example icon button with a home icon"
        matTooltip="Tu cuenta bancaria en formato IBAN: ES...">
        <mat-icon>info</mat-icon>
      </button>
    </div>
    <p>Introduce el número de cuenta en formato IBAN donde deseas recibir los pagos. A continuación deberás subir un justificante de tu IBAN.</p>
    <form [formGroup]="ibanForm" novalidate (ngSubmit)="onSubmit()" class="iban_form">
      <mat-form-field appearance="fill" color="primary" class="inputRow">
        <mat-label>ES...</mat-label>
        <input matInput formControlName="iban" #iban>
        <mat-icon matSuffix *ngIf="ibanForm.valid">check</mat-icon>
        <mat-error *ngIf="!ibanForm.get('iban')?.valid && (ibanForm.get('iban')?.dirty || submitted)"><mat-icon>info</mat-icon> Introduce los 24 carácteres de tu IBAN sin espacios.</mat-error>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="!ibanForm.valid" *ngIf="!ibanCHB">
        <div class="into_btn"><p>Guardar Iban</p><mat-spinner [diameter]="20" *ngIf="progressBar"></mat-spinner></div>
      </button>
    </form>
  </div>


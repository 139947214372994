import { Component } from '@angular/core';
@Component({
  selector: 'app-to-finance',
  templateUrl: './to-finance.component.html',
  styleUrls: ['./to-finance.component.scss']
})
export class ToFinanceComponent  {

  constructor() {
  }
  
}

<div class="reg_container">
  <div class="Page_container">
    <app-header-about></app-header-about>
    <div class="reg_content">
      <div class="title_content">
        <h1>
          fácil de explicar,<br />
          fácil de financiar
        </h1>
      </div>
    </div>
    <div class="content-container">
        <div class="text-content">
<p>
        <strong>Queremos ayudarte</strong> en tu negocio para que tus clientes
        tengan la financiación que más se adapta a sus necesidades.
        <strong>No tenemos letra pequeña. No tendrán dudas.</strong>
      </p>
      <p>
        Para ello la propuesta de <strong>CONFIA</strong>
        es clara, rápida, segura y gracias a un proceso sencillo realizarás las
        operaciones con la máxima confianza y garantías.
        <strong>Sabemos lo importantes que son los clientes</strong>
        y por ello nuestras soluciones <strong>CONFIA</strong> te ayudarán a
        ofrecerles <strong>la financiación más sencilla y transparente.</strong>
      </p>
        </div>    
      <div class="card-content">
        <div class="card-item" *ngFor="let item of howAboutCard">
          <img src="{{ item.image.src }}" alt="" />
          <h2>{{ item.title }}</h2>
          <p>{{ item.text }}</p>
        </div>
      </div>

      <h2>Ya está, eso es todo!</h2>
      <div class="text-content">
         <p>
        Si la financiación se ha concedido, el cliente ya puede acceder a su
        producto o servicio y en <strong>CONFIA</strong> procederemos a hacer la
        transferencia al comercio en las condiciones pactadas.
      </p>
      <p>
        Se trata de
        <strong>un proceso rápido, sencillo y sin letra pequeña.</strong>
      </p>
      <p>Te esperamos, <strong>PRÚEBANOS!</strong></p> 
      </div>
      
    </div>
  </div>
</div>

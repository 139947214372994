import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmedValidator } from 'src/app/core/extensions/confirmPasword';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public resetPasswordForm : FormGroup
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private userService : UserService) { 
    this.resetPasswordForm = formBuilder.group({
      previousPassword : ['',[Validators.required] ],
      newPassword: ['',[Validators.required] ],
      repeatNewPassword: ['',[Validators.required] ]
    },
    {
      validators: ConfirmedValidator('newPassword', 'repeatNewPassword')
    })
  }

  ngOnInit(): void {

  }

  onSubmit(){
    if (this.resetPasswordForm.valid){
      const data = {
        newPassword: this.resetPasswordForm.get('newPassword').value,
      }
      this.userService.resetPassword(localStorage.getItem('token'), data.newPassword).subscribe((res)=>{

      })
    }

}
}

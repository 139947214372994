import { AuthService } from 'src/app/core/services/auth.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-landing',
  animations: [
    trigger('openCloseBurgerMenu', [
      state('top', style({
        top: '56px',
        opacity: 1,
      })),
      state('bottom', style({
        top: '-700px',
        opacity: 0.5
      })),
      transition('top => bottom', [
        animate('0.5s')
      ]),
      transition('bottom => top', [
        animate('0.5s')
      ]),
    ]),
  ],
  templateUrl: './header-landing.component.html',
  styleUrls: ['./header-landing.component.scss']
})
export class HeaderLandingComponent implements OnInit {
  public hiddenNav: boolean = true;
  public visible: boolean = false;
  public menu: boolean = false
  public panelOpenState = false;
  public panelOpenStateSecond = false;
  public panelOpenStateThird = false;
  public footerMenu: boolean = false;
  breakpoint: number = 768;
  isOpen = false;

  toggle() {
    this.isOpen = !this.isOpen;
  }
  constructor( private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    const w = window.innerWidth;
    if (w <= this.breakpoint) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }
  showLogin(){
     this.hiddenNav = false;
  }
  hideLogin(){
    this.hiddenNav = true;
  }
  showMenu(){
    this.menu = true;
  }
  hideMenu(){
    this.menu = false;
    this.panelOpenState = false;
    this.panelOpenStateSecond = false;
    this.panelOpenStateThird = false;
  } 
  showFooter(){
    this.footerMenu = true;
  }
  hideFooter(){
    this.footerMenu = false;
  }

  onResize(event:any) {
    const w = event.target.innerWidth;
    if (w <= this.breakpoint) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

}
<div class="header-container">
    <div class="header-main-contain">
      <div class="imgs-contain">
        <img src="../../../../assets/LG-Confia-Color-01.svg" alt="" [routerLink]="'/'"/>
      </div>
      <nav class="header-nav">
        <ul class="nav-list">
          <li
            class="nav-item"
            [routerLink]="'/que-es-confia'"
            routerLinkActive="activeRoute"
            
          >
            <a>QUÉ ES CONFIA </a>
          </li>
          <li
            class="nav-item"
            [routerLink]="'/como-funciona'"
            routerLinkActive="activeRoute"
          >
            <a>CÓMO FUNCIONA</a>
          </li>
          <li
            class="nav-item"
            [routerLink]="'/preguntas-confia'"
            routerLinkActive="activeRoute"
          >
            <a> PREGUNTAS </a>
          </li>
        </ul>
      </nav>
      <mat-divider color="primary"></mat-divider>
    </div>
  </div>

export const allRegex = {
    regexNumber: /^[0-9]{9}$/,
    regexDNI: /([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])/g,
    regexNIE: /^[XYZ][0-9]{7}[A-Z]$/i,
    regexDate: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/,
    regexNames:/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
    regexPhoneAndEmail:/^[0-9]{9}|[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    regexNumberCvv:/[0-9]{3}/,
    regexNumberCard:/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}(?:2131|1800|35\d{3})\d{11})$/,
    regexDateCard:/^(0[1-9]|1[0-2])(\/|-)([0-9]{4})$/,
    regexPassword: /(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
    regexIban: /^ES[0-9]{2}[0-9]{20}$/,
    regexNumSons: /^([0-9]{1}|[0-1]{1}[0-9]{1}|[20]{2})$/,
    regexCp:/\b\d{4,5}\b/,
    regexCpTwo:/(0[1-9]|5[0-2]|[0-4][0-9])[0-9]{3}/,
    regexyearsEmployed: /^([0-9]{1}|[0-6]{1}[0-9]{1}|[70]{2})$/,
    regexmonthEmployed: /^([0-9]{1}|[0-1]{1}[0-2]{1})$/,
    regexMonthlyIncome:/^([0-9]{1}|[0-9]{2}|[0-9]{3}|[0-9]{4}|[0-2]{1}[0-9]{4}|[30000]{5})$/,
    regexCode: /^[0-9]{6}$/,
    regexPhone:/^\+?(6\d{2}|7[1-9]\d{1})\d{6}$/,
    regexDniAndNie: /^([0-9]{8}[A-Z])|[XYZ][0-9]{7}[A-Z]$/
}
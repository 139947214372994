import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string) {
    if (title === undefined) {
      title = "Confia";
    }
    this.title.setTitle(title + ' | Confia');
  }

  updateOgUrl(url: string) {
    if (url === undefined) {
      url = "https://www.confia.com.es";
    }
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    if (desc === undefined) {
      desc = "Financiación con Confia";
    }
    this.meta.updateTag({ name: 'description', content: desc })
  }

  updateKeywords(keywords: string) {
    if (keywords === undefined) {
      keywords = "Financiación";
    }
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }

  addNoIndex() {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }


}

<div class="headercontainer">
  <div class="header-main-contain">
    <div class="imgs-contain">
      <img
        class="logo-confia"
        src="../../../../assets/LG-Confia-Color-01.svg"
        alt="logo confia"
        (click)="navigateTo(idBorrower)"
      />
    <!--   <img class="logo-afiliado" src="https://dev.zank.com.es/logos/{{businessLogo}}" alt="logo afiliado" /> -->
    </div>
    <nav class="header-nav" *ngIf="userType == 'afiliado'">
      <ul class="nav-list">
        <a class="nav-item" [routerLink]="'/afiliado'"  routerLinkActive="activeRoute" [routerLinkActiveOptions]="{ exact: true }">
          Panel de control
        </a>
        <a
        *ngIf="simuladorEnabled == 'true'"
          class="nav-item"
          [routerLink]="'/afiliado/simulador'"
          routerLinkActive="activeRoute"
          
        >
          Simulador
        </a>
        <a
          *ngIf="transfersEnabled == 'true'"
          class="nav-item"
          [routerLink]="'/afiliado/transferencias'"
          routerLinkActive="activeRoute"
          
        >
          Transferencias
        </a>
        <a
        *ngIf="cobrosEnabled == 'true'"
        class="nav-item"
        [routerLink]="'/afiliado/cobros'"
        routerLinkActive="activeRoute"
        
      >
        Cobros
      </a>
        <a
        *ngIf="usuariosEnabled == 'true'"
          class="nav-item"
          [routerLink]="'/afiliado/usuarios'"
          routerLinkActive="activeRoute"
        >
          Vendedores
        </a>
        <a
        *ngIf="linksEnabled == 'true'"
          class="nav-item"
          [routerLink]="'/afiliado/enlaces'"
          routerLinkActive="activeRoute"
        >
          Enlaces
        </a>
        <a 
        *ngIf="reportsEnabled == 'true'"
        class="nav-item"
        [routerLink]="'/afiliado/reports'"
        routerLinkActive="activeRoute"
      >
        Informes
      </a>
      </ul>
    </nav>
    <mat-divider color="primary"></mat-divider>
  </div>
</div>

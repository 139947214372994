import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit, AfterViewInit {
 
  constructor( private router: Router, private auth: AuthService) { 
    if(!environment.maintenance){
      this.auth.logout()
      this.router.navigate(['/crea-tu-cuenta'])
    }
  }
  ngAfterViewInit(){
    
  }
  ngOnInit(): void {
    
  }

}

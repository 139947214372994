<app-header-landing *ngIf="!hiddenHeader"></app-header-landing>
<app-header-loggin-in *ngIf="hiddenHeader" [enabledPaths]="enabledPaths" [name]="name"></app-header-loggin-in>
<div [ngClass]="{
    'affiliateMode':afilliateType,
    'borrowerMode':borrowerType,
    'landingMode':!afilliateType && !borrowerType
}">
    <router-outlet><app-spinner></app-spinner></router-outlet>
</div>
<app-footer-landing [hiddenHeader]="hiddenHeader"></app-footer-landing>



import { Component } from '@angular/core';
import { HowAboutCard } from 'src/app/core/models/how-about';

@Component({
  selector: 'app-confia-twenty',
  templateUrl: './confia-twenty.component.html',
  styleUrls: ['./confia-twenty.component.scss'],
})
export class ConfiaTwentyComponent {
  public financeCard!: HowAboutCard[];
  constructor() {
    this.financeCard = [
      {
        image: {
          src: '../../../../assets/imgConfia/img/confiaimg/ICO-Entrada-Confia-01.svg',
          alt: 'entry_icon',
        },
        title: 'Entrada en tienda',
        startText: '15',
        endText: '=150',
      },
      {
        image: {
          src: '../../../../assets/imgConfia/img/confiaimg/ICO-Cuota-Confia-02.svg',
          alt: 'share_icon',
        },
        title: 'Cuota mensual',
        text: '50',
      },
      {
        image: {
          src: '../../../../assets/imgConfia/img/confiaimg/ICO-Euro-Confia-01.svg',
          alt: 'euro_icon',
        },
        title: 'Importe a financiar',
        text: '850',
      },
    ];
  }
}

import { BehaviorSubject, Observable } from 'rxjs'; 
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDataService{

  userType$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  name$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {
    /* const sub = interval(300).subscribe( () => {
        this.setUserType(localStorage.getItem('userType'))
        this.setNameSession( localStorage.getItem('userType') == 'prestatario' ? localStorage.getItem('userName') : localStorage.getItem('corporationName') )
    })
    if(localStorage.getItem('userType')){
      sub.unsubscribe()
    } */
    this.setUserType(localStorage.getItem('userType'))
    localStorage.getItem('userType') == 'prestatario' ? this.setNameSession(localStorage.getItem('userName'))  : this.setNameSession(localStorage.getItem('corporationName')) 
   }
  
  getNameSession(): Observable<string> { 
    return this.name$.asObservable();
  }

  setNameSession(element: string) {
    this.name$.next(element);
  }

  getUserType(): Observable<string> { 
    return this.userType$.asObservable();
  }

  setUserType(element: string) {
    this.userType$.next(element);
  }
}

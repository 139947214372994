import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-check-email',
  templateUrl: './check-email.component.html',
  styleUrls: ['./check-email.component.scss']
})
export class CheckEmailComponent implements OnInit {

  public userEmail : string; 
  constructor(private router : ActivatedRoute) { }

  ngOnInit(): void {
    this.router.queryParams.subscribe(
      params =>{
        this.userEmail = params['email']
      })
  }

}

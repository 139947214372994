<form [formGroup]="form">
  <div class="img-preview">
    <div *ngIf="upload">
      <img *ngIf="allSelectedFiles.length <= 1" src="{{preview}}" alt="preview" />
      <svg *ngIf="allSelectedFiles.length > 1" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-box-multiple" width="200" height="200" viewBox="0 0 24 24" stroke-width="1.5" stroke="#002b3d" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <rect x="7" y="3" width="14" height="14" rx="2" />
        <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
      </svg>
      <div *ngFor="let item of allSelectedFiles">
        <p>{{item.name}}</p>
      </div>
    </div>
  </div>

  
  <label for="element" *ngIf="!uploaded">
    <div class="button-container">
      <div class="custom-input-file">
        <mat-icon>camera_enhance</mat-icon>
        <input  (change)="onFileSelected($event)" id="element" type="file"  multiple
          accept="image/*, application/pdf,application/vnd.ms-excel" formControlName="element" class="input-file">
        <p>{{buttonText}}</p>
      </div>
    </div>
  </label>


  <!-- BOTÓN DE SUBIR -->
  <div *ngIf="upload" [hidden]="hideUploadButton">
    <button *ngIf="!uploaded" type="button" class="button-upload" (click)="onSubmit()" >
      <p>{{buttonUpload}}</p>
    </button>
    <button *ngIf="uploaded" type="button" class="button-uploaded" disabled>
      <p>{{buttonUpload}}</p>
    </button>
  </div>
  <div [hidden]="!hideUploadButton">
    <button *ngIf="_uploading" type="button" class="button-upload" (click)="onSubmit()" >
      <p>{{buttonUpload}}</p>
    </button>
    <button *ngIf="uploaded" type="button" class="button-uploaded" disabled>
      <p>{{buttonUpload}}</p>
    </button>
  </div>
 <!--  <button *ngIf=" uploaded && hideUploadButton" type="button" class="button-uploaded" disabled>
    <p>{{buttonUpload}}</p>
  </button> -->
</form>
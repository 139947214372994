import { Component, Input, OnInit } from '@angular/core';
import { HowAboutCard } from 'src/app/core/models/how-about';

@Component({
  selector: 'app-finance-card',
  templateUrl: './finance-card.component.html',
  styleUrls: ['./finance-card.component.scss']
})
export class FinanceCardComponent implements OnInit {
@Input() financeCard!: HowAboutCard;
  constructor() { 

  }

  ngOnInit(): void {
  }

}


<div class="reg_container">
  <div class="Page_container">
<app-header-finance></app-header-finance>
<div class="page-container">
  <div class="img-container">
    <h1 class="title-big">¿Preguntas?</h1>
  </div>
  <div class="faq-content">
    <p class="faq-title">Si quieres ser afiliado en Confia.</p>
    <p class="accordion-title">Cómo empezar en Confia</p>
    <div class="accordion-content">
      <mat-accordion >
        <mat-expansion-panel *ngFor="let item of comoEmpezar">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ item.title }}</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description
            >{{ item.description }}
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <p class="accordion-title">Realizando una solicitud de financiación</p>
    <div class="accordion-content">
      <mat-accordion >
        <mat-expansion-panel *ngFor="let item of solicitudFinanciacion">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ item.title }}</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description
            >{{ item.description }}
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <p class="accordion-title">Conociendo el estado de una financiación</p>
    <div class="accordion-content">
      <mat-accordion >
        <mat-expansion-panel *ngFor="let item of estadoFinanciacion">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ item.title }}</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description
            >{{ item.description }}
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <p class="accordion-title">Otras preguntas</p>
    <div class="accordion-content">
      <mat-accordion >
        <mat-expansion-panel *ngFor="let item of otrasPreguntas">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ item.title }}</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description
            >{{ item.description }}
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
</div>
</div>

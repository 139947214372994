import { UpdateStatus } from 'src/app/core/extensions/updateStatus';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { allRegex } from 'src/app/core/extensions/regex';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-iban-panel',
  templateUrl: './iban-panel.component.html',
  styleUrls: ['./iban-panel.component.scss']
})
export class IbanPanelComponent implements OnInit {
public ibanForm: FormGroup;
public submitted: boolean = false;
public today: string;
public ibanRes: any;
public formData: any;
public progressBar: boolean = false;

@Output() ibanOk: EventEmitter<boolean> = new EventEmitter()
@Output() ibanNumber: EventEmitter<string> = new EventEmitter()
@Input() buttonCHB: boolean = false;
@Input() ibanCHB: boolean;
@Input() setSignedFalse: boolean;

  constructor( private formBuilder: FormBuilder, private userService: UserService, private updateStatus: UpdateStatus) {
    this.ibanForm = this.formBuilder.group({
      iban: ["",[Validators.required, Validators.pattern(allRegex.regexIban)]],
      validated: [true],
      sendDate: [this.today],
      validationDate: [this.today],
      signed: [true],
      signedDate: [this.today],
    })
    const pipe = new DatePipe('en-US');
    this.today = pipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss');
    
   }
   
  ngOnInit(): void {
   this.ibanForm.valueChanges.subscribe( res =>{
    if(this.ibanForm.valid){
      this.ibanOk.emit(true)
    }else{
      this.ibanOk.emit(false)
    }
   })

  }
/*   withoutSpaces(){
    this.ibanForm.valueChanges.subscribe(
      res => {
        this.ibanValue = this.ibanForm.get('iban')?.value.split(" ").join("")
      }
    )
  } */
  public onSubmit(): void {
    this.progressBar = true;
    this.submitted = true;
    if(this.ibanForm.valid){
      const data = {
        iban: this.ibanForm.get('iban')?.value,
        validated: true,
        sendDate: this.today,
        validationDate: this.today,
        signed: this.setSignedFalse ? false : true,
        signedDate: this.today,
      }
      this.ibanNumber.emit(data.iban)
      this.formData = data
      this.submitted = false;
      this.ibanForm.reset();
      }
      this.setAccount(this.formData)
      this.progressBar = false;
      if(this.buttonCHB){
        this.updateStatus.changeStatus();
      }
      
    }


    setAccount(data){
      this.userService.generateIbanId(data).subscribe(
        res => {
          this.ibanRes = res;
          localStorage.getItem('userBorrowerId') ?
          this.userService.setUserAccount(localStorage.getItem('userBorrowerId'), this.ibanRes["@id"]).subscribe(
            () => {
      
          }) : console.log("")
          localStorage.getItem('userType') && localStorage.getItem('userType') == 'afiliado' ? 
          this.userService.setAffiliateAccount(localStorage.getItem('affiliateId'), this.ibanRes["@id"]).subscribe(
            () => {

            }
          ) : console.log("")
        }
        )
        
    }
}

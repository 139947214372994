<div class="reg_container">
    <div class="Page_container">
        <app-nav-legal-info></app-nav-legal-info>
        <div class="reg_content">
            <div class="title_content">          
                <h2>Todas las normas por las que nos regimos.</h2>               
            </div>
            <div class="columns">
                <div class="forms_container">
                    <div class="generalConditions">
                        <h2>Condiciones generales</h2>
                        <a href="../../../../assets/pdf/Condiciones_generales_CONFIA.pdf">Descargar condiciones generales</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


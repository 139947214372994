<div class="reg_container">
  <div class="Page_container">
<app-header-finance></app-header-finance>
<div class="page-container">
  <div class="img-container">
    <h1 class="title-big">
      Rellenas una solicitud, la aprobamos y recibes tu dinero.
    </h1>
  </div>
  <div class="steps-container">
    <div class="row-text-right">
      <p>
        Con la plataforma tecnológica más avanzada del mercado, todo es
        sencillo. Sin preguntas incómodas, con aprobación en el momento y sin
        papeles. ¿Necesitas algo más?
      </p>
    </div>
    <div class="row-img-first">
      <div>
        <h1 class="color-title">1. Crea una financiación en Confia.</h1>
        <p class="img-text">En cualquier momento y lugar.</p>
      </div>
    </div>
    <div class="row-text-left">
      <p>
        Te registras como afiliado en Confia y creas una nueva solicitud de
        préstamo. Tu o tu cliente podéis rellenar la solicitud, incluso una
        parte cada uno. Desde tu casa, desde una estación, desde tu oficina...
        en definitiva, como tu prefieras.
      </p>
    </div>
    <div class="row-img-second">
        <h1 class="color-title">2. Tomamos una decisión en tiempo real.</h1>
        <p class="img-text">Respondiendo de inmediato.</p>
    </div>
    <div class="row-text-right">
      <p>
        Confia filtra a las personas que solicitan financiación de acuerdo a los
        datos rellenados y a su perfil demográfico. Es capaz de dar una decisión
        en cuestión de segundos, de tal forma que inmediatamente sabrás si una
        financiación está aprobada.
      </p>
    </div>
    <div class="row-img-third">
        <h1 class="color-title">3. Recibes tu dinero en tiempo record.</h1>
        <p class="img-text">De forma directa, rápida y sencilla.</p>
    </div>
    <div class="row-text-left">
      <p>
        Una vez que todo está correcto, recibes tu dinero de forma directa,
        rápida y sencilla en tu cuenta bancaria. Siempre dispones de toda la
        información sobre el historial de préstamos solicitados de tal forma que
        puedes utilizar tu base de clientes para ofrecerles ofertas e
        incentivos.
      </p>
    </div>
  </div>
</div>
</div>
</div>

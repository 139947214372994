import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { SEOService } from './core/services/SEO/seoservice.service';
import { filter, map, mergeMap } from "rxjs/operators";
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { UserDataService } from './core/services/user/userData.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  public hiddenHeader: boolean = false;
  public foo: boolean = false;
  public userType: string;
  public afilliateType: boolean = false;
  public borrowerType:boolean = false;
  _login: boolean; 
  onboardingService: any;
  public currentRoute: any;
  public headerOnLanding: boolean =  false;
  public enabledPaths: any;
  public name!: string;


  constructor( 
    private auth: AuthService,
    private router: Router, 
    private seoService: SEOService, 
    private _route: ActivatedRoute,
    private zendeskService: NgxZendeskWebwidgetService,
    private userDataService: UserDataService
    ) {
   
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this._route), 
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),

      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)

    )
        .subscribe( (event: any) => {
      
          this.seoService.updateTitle(event['title']);
          this.seoService.updateOgUrl(event['ogUrl']);
          this.seoService.updateDescription(event['description'])
          this.seoService.updateKeywords(event['keywords'])
          if (event['noIndex'] === true) {
            this.seoService.addNoIndex();
          }
  });
  }

  ngOnInit(): void {  
  
  this.zendeskService.initZendesk()
  this.checkExpiry();
  this.userDataService.getUserType().subscribe(
    res => {
      this.userType = res;
      if(this.userType === 'afiliado'){
        this.afilliateType = true;
        this.borrowerType = false;
      } else if (this.userType === 'prestatario'){
        this.borrowerType = true;
        this.afilliateType = false;
      }
      if(this.userType == 'afiliado' || this.userType == 'comercial'){
        this.openZendesk()
      }
   
    }
  )  
    this.auth.setTokenSessionStorage();
    this.auth.getTokenSessionStorage().subscribe( 
      res => {
        this._login = res;
        if(this._login){
          this.hiddenHeader = true;
          this.enabledPaths = {
            linksEnabled: localStorage.getItem('links'),
            simuladorEnabled: localStorage.getItem('simulador'),
            reportsEnabled: localStorage.getItem('reports'),
            usuariosEnabled: localStorage.getItem('usuarios'),
            cobrosEnabled: localStorage.getItem('cobrosEnabled'),
          }
        }else{
          this.hiddenHeader = false;
          this.closeZendesk();
        }
      })
      
    this.userDataService.getNameSession().subscribe(
      res => {
        this.name = res
      }
    )
     
    //scrollToTop para que al cargar rutas lo haga desde arriba
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      const scrollToTop = window.setInterval(() => {
        const position = window.pageYOffset;
        if (position > 0) {
            window.scrollTo(0, 0);
        } else {
            window.clearInterval(scrollToTop);
        }
      });
  });
  }
  checkExpiry(){
    const dateNow = new Date(Date.now())
    const now = dateNow.getTime()
    if(JSON.stringify(now) > localStorage.getItem('expiry')){
      localStorage.clear();
    }
  }
  closeZendesk() {
    if (this.zendeskService.isInitialized) {
      this.zendeskService.zE('webWidget', 'hide');
    }
  }
  openZendesk() {
    if (this.zendeskService.isInitialized) {
      this.zendeskService.zE('webWidget', 'show');
    }
  }

}

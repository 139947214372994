import { Component, OnInit } from '@angular/core';
import { HowAboutCard } from 'src/app/core/models/how-about';

@Component({
  selector: 'app-how-about',
  templateUrl: './how-about.component.html',
  styleUrls: ['./how-about.component.scss']
})
export class HowAboutComponent implements OnInit {
  public howAboutCard: HowAboutCard[]=[
    {
      image:{
        alt:"",
        src:"../../../../../assets/about/ICO-Movil-Confia-01.svg",
      },
      title:"OFERTA CLARA Y RÁPIDA",
      text:"Como comercio afiliado, ofreces a tus clientes financiar sus compras o servicios. Inicia tu solicitud con nuestro formulario corto en tu portal de comercio afiliado."
  
    },
    {
      image:{
        alt:"",
        src:"../../../../../assets/about/ICO-Tienda-Confia-01.svg",
      },
      title:"TRAMITES 100% ONLINE",
      text:"Permite que el cliente, en su móvil, de manera confidencial y cómoda complete el formulario personal. También puedes guiarle en el formulario personal pero siempre será el cliente quien valide los datos en su móvil.",

    },
    {
      image:{
        alt:"",
        src:"../../../../../assets/about/ICO-Contrato-Confia-01.svg",
      },
      title:"VERIFICACIÓN Y FIRMA",
      text:"Tras la verificación de identidad del cliente llega el momento de firmar el contrato también de forma online.",

    }
  ]
  
  constructor() { }

  ngOnInit(): void {    
  }
}


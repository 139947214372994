<div class="reg_container">
  <div class="Page_container">
    <app-header-about></app-header-about>
    <div class="reg_content">
      <div class="title_confiaPlan">
          <h2>CONFIA<span>5</span></h2>
        <h4 class="first_paragraph">
          Sin costes para el comercio, cinco cuotas en cinco meses y entrada en
          tienda del 5%
        </h4>
        <h4 class="second_paragraph">Fácil para tu cliente, más fácil para ti!</h4>
      </div>
      <div class="columns">
        <div class="info_ConfiaPlan">
            <ul class="info_list">
                <li>
                    Con intereses para el cliente.<span>TIN CLIENTE.</span>
                </li>
                <li>
                    <span>Sin coste para el comercio.</span> (0% de descuento sobre el importe a financiar).
                </li>
                <li>
                    Cliente deja un <span>5% de entrada en tienda</span> (Contacta con tu comercial para ver promociones).
                </li>
                <li>
                    La duración del producto es cerrada: <span>5 meses.</span>
                </li>
                <li>
                  La <span>mensualidad</span> se calcula dividiendo el <span>PVP/5.</span>
                </li>
                <li>
                    % cancelación anticipada según los límites legales establecidos (0,5% - 1%).
                </li>
            </ul>
            <app-contact-form-shared></app-contact-form-shared>
        </div>
      </div>
      <p class="cards_title">EJEMPLO DE FINANCIACIÓN PVP DE 1.000€</p>
      <div class="info_cards">
        <div *ngFor="let item of financeCard">
          <app-finance-card
            [financeCard]="item">
          </app-finance-card>
        </div>
      </div>
      <p class="small_print">* En CONFIA apostamos por la transparencia: el Tipo Deudor Anual depende del Producto, el importe y de la duración del mismo. Oferta de financiación sujeta a la aprobación de CONFIA, Entidad financiadora Unión Financiera de Crédito EFC, SA. Importe del préstamo de 300€ a 10.000€. Plazo de duración de 5 meses. Sin comisión de formalización. Tipo de interés TIN 20,81% y TAE 22,92%; (condiciones sin oferta promocional). El simulador te permite escoger importe y plazo deseado y visualizar la TAE y el importe total a pagar. El importe de la última cuota y la TAE podrán variar en función del día de formalización.</p>
    </div>
  </div>
</div>
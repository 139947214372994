<div class="header-container">
    <div class="header-main-contain">
      <div class="imgs-contain">
        <img
          class="logo-confia"
          src="../../../../assets/LG-Confia-Color-01.svg"
          routerLink="/"
          alt="logo confia"
        />
      </div>
      <nav class="header-nav">
        <ul class="nav-list">
          <a class="nav-item" routerLinkActive="activeRoute" [routerLink]="'/condiciones-uso'">
            Condiciones de uso
          </a>
          <a
            class="nav-item"
            [routerLink]="'/condiciones-generales'"
            routerLinkActive="activeRoute"
          >
          Condiciones generales
          </a>
          <a
            class="nav-item"
            [routerLink]="'/condiciones-entidad-pago'"
            routerLinkActive="activeRoute"
          >
          Condiciones entidad pago
          </a>
          <a
            class="nav-item"
            [routerLink]="'/politica-privacidad'"
            routerLinkActive="activeRoute"
          >
          Política privacidad
          </a>
          <a
          class="nav-item"
          [routerLink]="'/cookies'"
          routerLinkActive="activeRoute"
        >
        Cookies
        </a>
        </ul>
      </nav>
      <mat-divider color="primary"></mat-divider>
    </div>
  </div>

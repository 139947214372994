<div class="dni_container" *ngIf="_creditData && _infoBorrower && userDocs && _secondaryReason && billActive != undefined">
  <div *ngFor="let item of docsFiltered">
    <app-toolbar-alerts *ngIf="item.alertMessage" [alertMessage]="item.alertMessage" [status]="'ko'"></app-toolbar-alerts>
  </div>
  <h2 class="title_container" *ngIf="userType == 'afiliado'">Introduce {{titleNie}} para que podamos verificar la identidad de tu
    cliente.</h2>
  <h2 class="title_container" *ngIf="userType != 'afiliado'">Introduce tu {{nonAffiliatetitleNie}} para que podamos verificar tu
    identidad.</h2>
  <div class="dni_contain">
    <p class="dni-text">Para una verificación rápida, asegúrate de que las imágenes que envíes sean <b>nítidas</b>, a
      <b>color</b> y se vean los <b>bordes del documento</b>. Si estás haciendo una foto, procura hacerla en un sitio
      iluminado, no utilices el flash para evitar brillos.</p>
    <img src="../../../../assets/img/ejemploDNIcorrecto.png" style="width: 100%; margin: 15px 0 30px;">
    <div class="dni_form">
        <div *ngFor="let item of expansionPanels">
          <div *ngIf="item.active">
            <mat-expansion-panel disabled *ngIf="item.uploaded" class="expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="check-icon">check</mat-icon>
                </mat-panel-title>
                <mat-panel-description class="hi">
                  <p class="doc-upload">DOCUMENTO SUBIDO</p>
                </mat-panel-description>
              </mat-expansion-panel-header>
            </mat-expansion-panel>

            <mat-expansion-panel class="expansion-panel" hideToggle *ngIf="!item.uploaded && item.active">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>camera_enhance</mat-icon>
                </mat-panel-title>
                <mat-panel-description class="title-panel-expansion">
                  {{item.title}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="upload_box">
                <div class="methods-payment-contain">
                    <div class="button-container">
                      <h3>{{item.title}}</h3>
                      <app-upload-files (uploadedEvent)="checkPanels($event)" (file)="receiveFile($event)" [nameElement]="item.type"
                          [buttonText]="item.buttonText" [buttonUpload]="item.buttonUpload"
                          [creditId]="idParam">
                      </app-upload-files>
                    </div>
                </div>               
              </div>
            </mat-expansion-panel>
          </div>
        </div>

        <div class="instrucciones_container">
          <p class="title_instrucciones"><strong>Cómo subir el DNI</strong></p>
          <ol>
            <li>
              <p class="std-text">Seleccionar el botón 'subir o hacer una foto de la parte frontal del DNI y escoge una
                de las dos opciones:</p>
              <ul>
                <li>
                  <p class="std-text">Subir una foto que ya tengas en tu móvil u otro dispositivo.</p>
                </li>
                <li>
                  <p class="std-text">Si tu dispositivo dispone de cámara, puedes hacer una foto. No utilices flash y
                    hazla en un sitio iluminado.</p>
                </li>
              </ul>
            </li>
            <li>
              <p class="std-text">Repite el proceso para la parte trasera del DNI</p>
            </li>
          </ol>
          <p class="title_instrucciones">¿No has podido finalizar el proceso?</p>
          <p class="std-text">Pruébalo desde el móvil. La mayoría de móviles disponen de navegadores actualizados y
            cámara por lo que no deberías tener problema.</p>
        </div>
    </div>
  </div>
  <button *ngIf="disableChangeStatus" mat-flat-button color="accent" routerLink="/afiliado" class="back_btn">Volver al panel de control</button>
</div>
<!-- <div class="revision_container" *ngIf="nextStep">
  <h4 class="modal-title">Tu financiación está aprobada</h4>
  <div class="revision_contain">
    <p class="revision_title">Verificación de documentos</p>
    <p class="revision_text">Estamos revisando tus documentos. Tardaremos como máximo 24 horas laborables.</p>
    <div class="revision_elements">
      <div class="revision_document" *ngFor="let item of expansionPanels">
        <div *ngIf="item.upload">
          <div class="document_title">
            <p>{{item.title}}</p>
            <span>En revisión</span>
          </div>
          <p class="document_text">En estos momentos estamos revisando el documento. Te avisaremos cuando terminemos.
          </p>
        </div>
      </div>
    </div>
  </div>
</div> -->
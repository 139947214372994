<div class="reg_container">
  <div class="Page_container">
      <app-nav-legal-info></app-nav-legal-info>
      <div class="reg_content">
          <div class="title_content">
              <h2>Tus datos están protegidos.</h2>
          </div>
          <div class="columns">
              <div class="forms_container">
                <div class="policy_privacy">
                  <h2>Política de privacidad</h2>
                  <p>
                    La presente Política de Privacidad regula los tratamientos de datos de
                    carácter personal realizados en el Portal CONFIA de Internet correspondiente
                    al dominio confia.com.es -directamente o mediante su acceso a dicho dominio
                    mediante redirección desde otro dominio- (en adelante, el “Portal CONFIA”). La
                    utilización del Portal CONFIA atribuye la condición de usuario (en adelante,
                    el “Usuario”) e implica el conocimiento y, en su caso, la aceptación de todas
                    las condiciones incluidas en esta Política de Privacidad. El Usuario debe leer
                    atentamente la presente Política de Privacidad en cada una de las ocasiones
                    que se proponga utilizar el Portal CONFIA, ya que ésta puede sufrir
                    modificaciones.
                  </p>
                  <h3>
                    1. Disposiciones comunes a todos los tratamientos de datos realizados en el
                    Portal CONFIA
                  </h3>
                  <h4>
                    Recogida, tratamiento y uso de los datos personales en el Portal CONFIA.
                  </h4>
                  <p>
                    En cumplimiento de lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento
                    Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las
                    personas físicas en lo que respecta al tratamiento de datos personales y a la
                    libre circulación de éstos (en adelante, "RGPD") y su normativa de desarrollo,
                    le informamos que los datos recogidos a través del Portal CONFIA serán
                    incorporados a ficheros automatizados de datos de carácter personal de los que
                    será responsable Zank Financial, S.L. (en adelante, “Zank” o El Proveedor).
                    Con carácter general, sus datos serán tratados con la finalidad de atender los
                    distintos tipos de relaciones que puedan surgir con el Proveedor como
                    consecuencia de las solicitudes, gestiones o trámites a que se refiera el
                    correspondiente formulario de recogida de datos. Los datos objeto de
                    tratamiento serán aquellos recogidos a través del correspondiente formulario
                    así como aquellos otros que surjan durante el mantenimiento de la relación
                    correspondiente, incluidos los derivados de la navegación en el sitio
                    confia.com.es y del uso de las herramientas y aplicaciones insertadas en la
                    plataforma. Todos los campos que aparecen señalados con un asterisco (*) en
                    cualesquiera de los formularios serán de obligada cumplimentación, de tal modo
                    que la omisión de alguno de ellos podrá comportar la imposibilidad de que
                    podamos prestarle los servicios solicitados. Adicionalmente, durante dicha
                    recogida de datos, es posible que se solicite su consentimiento para otra
                    serie de finalidades que no guardan una relación directa con la relación
                    correspondiente. En el caso de que no esté de acuerdo con dichos tratamientos
                    adicionales, marque o desmarque la casilla destinada al efecto según
                    corresponda. Asimismo, al enviarnos sus datos a través de los formularios
                    previstos o mediante correo electrónico a los buzones correspondientes, usted
                    declara que la información y los datos facilitados son exactos y veraces, así
                    como que es mayor de 18 años. Los servicios del Portal CONFIA no están
                    dirigidos a menores de 18 años por lo que no tenemos previsto consentimiento
                    paterno en ningún caso. Será responsabilidad del USUARIO mantener
                    permanentemente actualizados todos sus datos personales.
                  </p>
                  <h4>Conservación</h4>
                  <p>
                    Sus datos se conservarán por los periodos legalmente previstos para cada caso
                    y ello sin perjuicio de su derecho de cancelación.
                  </p>
                  <h4>Comunicaciones o cesiones de datos</h4>
                  <p>
                    Los datos recogidos en el Portal CONFIA podrán ser comunicados a terceros
                    cuando sea necesario para el desarrollo, mantenimiento y control de la
                    relación jurídica que se establezca o cuando exista autorización legal para
                    hacerlo. Adicionalmente, podrán comunicarse sus datos a otros terceros para
                    otras finalidades cuando se obtenga su consentimiento previo para ello en la
                    forma legalmente exigida. Concretamente, para la prestación de sus servicios,
                    el Proveedor facilita detalles sobre la información facilitada por el Cliente
                    tales como análisis de solvencia, empleo, ingresos y gastos, incidencias en
                    ficheros de morosidad, etc. a la Entidad (en adelante “CONFIA”) a la que se
                    hubiera solicitado el préstamo. CONFIA utilizará esa información para decidir
                    acerca de la concesión de este.
                  </p>
                  <h4>
                    Ejercicio de los derechos de acceso, rectificación, cancelación y oposición
                  </h4>
                  <p>
                    Los Usuarios podrán ejercer, en cualquier momento, sus derechos de acceso,
                    rectificación, supresión, oposición, portabilidad y limitación al tratamiento
                    de sus datos, así como revocar su consentimiento en los términos previstos
                    legalmente, dirigiéndose a la siguiente dirección electrónica:
                    protecciondatos.confia@confia.com.es y acompañando en cualquier caso copia del
                    documento que acredite su identidad.
                  </p>
                  <h4>Comunicaciones comerciales por vía electrónica</h4>
                  <p>
                    Durante la vigencia del Servicio, CONFIA a través del Proveedor podrá
                    remitirle información comercial o publicitaria por cualquier medio, incluidos
                    los medios electrónicos, en los casos en los que usted haya manifestado
                    consentimiento expreso en el formulario correspondiente. En todos los casos
                    anteriores, usted podrá oponerse a la remisión de comunicaciones comerciales
                    electrónicas mediante el envío de un correo electrónico a la siguiente
                    dirección: protecciondatos.confia@confia.com.es o a la siguiente dirección
                    postal: C/ Pelayo, 15. 33003 Oviedo; o usando los medios específicos que se
                    reconozcan en las propias comunicaciones comerciales que se le dirijan.
                  </p>
                  <h3>2. Disposiciones comunes a todos los tratamientos</h3>
                  <p>
                    Para poder solicitar y, en su caso, obtener a través del Portal CONFIA un
                    préstamo con CONFIA, entidad de financiación al consumo a la que el Proveedor
                    presta sus servicios, será necesario que se registre como Usuario. A tal
                    efecto, el Proveedor como responsable del fichero, solicitará una serie de
                    datos de carácter personal a través de los correspondientes formularios. Todos
                    los datos que se recojan durante el procedimiento de registro y cualquier otro
                    dato que facilite o se genere durante las solicitudes de crédito, la creación
                    y uso de Cuentas de Usuario, el desarrollo de los Contratos de Préstamo, así
                    como con carácter general, los datos personales que se recojan o generen como
                    consecuencia de la prestación del servicio por el Proveedor, serán
                    incorporados a ficheros de la responsabilidad del Proveedor y serán tratados
                    con la exclusiva finalidad de controlar y mantener la relación jurídica con el
                    Usuario. Dicha finalidad, comprende, sin carácter limitativo, la realización
                    de tratamientos con fines de verificar su identidad, realizar comprobaciones
                    sobre la veracidad y exactitud de los datos facilitados por los Clientes,
                    tramitar cobros y pagos, analizar riesgos, prevenir el fraude, gestionar el
                    cobro de cantidades impagadas, prevención del blanqueo de capitales, así como
                    realizar encuestas de satisfacción y sobre la calidad del servicio. Asimismo,
                    mediante la aceptación de la Política de Privacidad, el Prestatario consiente
                    expresamente la realización de tratamientos automatizados o no con el fin de
                    valorar y puntuar su capacidad crediticia, así como la consulta de ficheros de
                    información sobre solvencia patrimonial y crédito, incluyendo ficheros de
                    cumplimiento o incumplimiento de obligaciones dinerarias, con el fin de
                    enjuiciar su solvencia económica.
                  </p>
                  <h3>3. Disposiciones comunes</h3>
                  <p>
                    El Portal CONFIA puede utilizar Cookies durante la prestación del servicio con
                    las finalidades de facilitar un servicio de la sociedad de la información
                    previamente solicitado o con otros propósitos. Consulte la información
                    específica sobre <a routerLink="/cookies"><strong>cookies</strong></a>.
                  </p>
              </div>
              </div>
          </div>
      </div>
  </div>
</div>



import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-legal-info',
  templateUrl: './nav-legal-info.component.html',
  styleUrls: ['./nav-legal-info.component.scss']
})
export class NavLegalInfoComponent implements OnInit {
  public visible: boolean = false;
  breakpoint: number = 768;
  
  constructor() { }

  ngOnInit(): void {
    const w = window.innerWidth;
    if (w <= this.breakpoint) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  onResize(event:any) {
    const w = event.target.innerWidth;
    if (w <= this.breakpoint) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

}

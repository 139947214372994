import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AffiliateService } from 'src/app/core/services/affiliate/affiliate.service';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {
  @Input() creditId!: string
  @Input() afiliado?: string
  @Input() nameElement!: string
  @Input() userId?: string
  @Input() hideUploadButton?: boolean

  @Output() file: EventEmitter<string> = new EventEmitter();
  @Output() uploadedEvent: EventEmitter<any> = new EventEmitter()

  @Input() buttonText: string = 'HACER FOTO/SELECCIONAR';
  @Input() buttonUpload: string = 'SUBIR'

  preview: string;
  upload: boolean = false;
  _uploading: boolean = false;
  uploaded: boolean = false;
  selectedFile?: any[];
  nameFile?: string;
  allSelectedFiles: any = [];


  form: FormGroup;

  element: string;

  constructor(private sanitizer: DomSanitizer, private formBuilder: FormBuilder, private affiliateService: AffiliateService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      element: ['']
    });
  }



  onSubmit() {
    this.element = this.form.get('element')?.value;
    this.file.emit(this.element)
    const idCredit = this.creditId;
    const formData: FormData = new FormData();
    const formName = this.nameElement;
    const afiliado = this.afiliado;
    const userId = this.userId;
    idCredit ? formData.append('loanId', idCredit) : null;
    formData.append('type', formName);
    formData.append('frontend', '1');
    userId ? formData.append('userId', userId) : null
    this.afiliado ? formData.append('afiliado', afiliado) : null;
    
    this.allSelectedFiles.forEach((e) => {
      const file = e;
      formData.delete('file')
      formData.append('file', file);
      this.affiliateService.uploadFile(formData).subscribe({

        next: (res) => {
          this.buttonUpload = 'SUBIDO CORRECTAMENTE';
          this.uploaded = true;
        },
        error: (err) => {
          /* console.log(err) */
        },
        complete: () => {
          this.checkFiles()
        }
      }
      )
    })

    this._uploading = true
    this.buttonUpload = 'SUBIENDO...';

  }

  checkFiles() {
    this.uploadedEvent.emit({ uploaded: this.uploaded, type: this.nameElement })
  }

  onFileSelected(event) {
    this.allSelectedFiles = []
    this.selectedFile = event.target.files;
    for (let i = 0; i < this.selectedFile.length; i++) {
      const element = this.selectedFile[i]
      this.allSelectedFiles.push(element)
    }

    this.allSelectedFiles.forEach(element => {
      this.nameFile= element.name
    })

    this.convertFileBase64(this.allSelectedFiles[0]).then((img: any) => {
      this.preview = img.base
    })

    this.upload = true;
    this.buttonText = 'VOLVER A SELECCIONAR IMAGEN';

  }


  //llevar a base 64 para obtener la codificación de la imagen y poder realizar la previsualización
  convertFileBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null
    }
  })

}
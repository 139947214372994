import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { finalize, Observable } from "rxjs";
import { SpinnerService } from "../services/spinner/spinner.service";


@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    
    public activeRequests : number = 0;
    skeleton: any

    constructor( private spinnerService: SpinnerService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinnerService.skeletonLoading$.subscribe(
            res => {
                this.skeleton = res
                
            }
        )
        if(this.skeleton){
            this.spinnerService.hideLoading()
            return next.handle(request)
        }else{
            if(this.activeRequests > 0 && !this.spinnerService.loading) {
               this.spinnerService.showLoading();
            }
            if(!request.url?.includes('checkStatus') && !request.url?.includes('/checkExistingLoan?')){
                this.activeRequests++;
            }
    
            
                return next.handle(request).pipe(
                    finalize(() => {
                        if(!request.url?.includes('checkStatus') && !request.url?.includes('checkExistingLoan')){
                            this.activeRequests--;
                        }
                        if (this.activeRequests === 0) {
                           this.spinnerService.hideLoading();
                        }
                        
                    })
                )
        }

        
    }
}

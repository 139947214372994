import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-why-financing',
  templateUrl: './why-financing.component.html',
  styleUrls: ['./why-financing.component.scss']
})
export class WhyFinancingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const myChart = new Chart("myChart", {
      type: 'bar',
      data: {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio','Julio'],
          datasets: [
            {
              label: 'bottom',
              data: [11,12,13,14,13,12,15],
              backgroundColor: 'silver',
            },
            {
              label: 'top',
              data: [0,1,2,3,4,5,6],
              backgroundColor: '#002c3e',
            },
          ]
      },
      options: {
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          }
      }
  });
  }

}

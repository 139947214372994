import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CotitularModel, CreditData, DocumentsModel, FinancingModel, InfoBorrower, UsersModel } from 'src/app/core';
import { AffiliateService } from 'src/app/core/services/affiliate/affiliate.service';
import { BorrowerService } from 'src/app/core/services/borrower/borrower.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-documents-borrower',
  templateUrl: './documents-borrower.component.html',
  styleUrls: ['./documents-borrower.component.scss'],
})
export class DocumentsBorrowerComponent implements OnInit {

  public creditId: string;
  public creditData!: CreditData;
  public infoBorrower!: InfoBorrower;
  public userDocs: DocumentsModel[];
  public cotitular!: CotitularModel;
  public secondaryReason!: any;
  public secondaryReasons!: any[];
  public userData!: UsersModel;
  public financing!: FinancingModel;
  public activateTitle: boolean = false;
  public financingCode: string = "";
  public statusBorrower!: string


  constructor( 
    private route: ActivatedRoute, 
    private borrowerService: BorrowerService,
    private userService: UserService,
    private affiliateService: AffiliateService,
    ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
      this.creditId = params['id'];
      localStorage.setItem('idBorrower', this.creditId)
        this.borrowerService.creditData(this.creditId).subscribe(
          res => {
            this.creditData = res;
            const borrowerPath = this.creditData.infoPrestatario?.split("/");
            const borrowerId = borrowerPath?.[borrowerPath?.length -1]; 
            const userIdPath = this.creditData.user?.split("/");
            const userIdDoc = userIdPath?.[userIdPath?.length -1];
            localStorage.setItem('userBorrowerId', userIdDoc)
          
            if(this.creditData.cotitular){
              const cotitularIdPath = res.cotitular?.split("/");
              const cotitularId = cotitularIdPath?.[cotitularIdPath?.length -1];
              this.borrowerService.getCotitular(cotitularId).subscribe(
                res => {
                  this.cotitular = res
                }
              )
            }
            this.borrowerService.infoBorrowers(borrowerId).subscribe(
              data => {
                this.infoBorrower = data
                this.affiliateService.getSecondaryReason(localStorage.getItem('affiliateId')).subscribe(
                  data => {
                    this.secondaryReasons = data['hydra:member'];
                    this.setSecondaryReason();         
                  })
                })
            }
          )
          this.userService.getUser(localStorage.getItem('userId')).subscribe(
            res => {
              this.userData = res
            }
          )
        })
  }
  setSecondaryReason() {
    if (this.creditData.secondaryReason == null) {
      this.secondaryReason = this.secondaryReasons
    } else {
      this.secondaryReason = this.secondaryReasons.filter(s => {
        return (s['@idSecondaryReason'] == this.creditData.secondaryReason)
      })
    }
  }

}


<div  
[ngClass]="{
    'loginForm': hiddenText,
    'loginRegister':!hiddenText,
    'hiddenNav':hiddenNav
}">
    <h2 [hidden]="hiddenText">Ya estoy registrado</h2>
    <form class="email_form" [formGroup]="registerForm" novalidate (ngSubmit)="onSubmit()" (keydown.enter)="onSubmit()">
        <mat-form-field appearance="standard">
            <input  placeholder="Email*" matInput formControlName="_username" #_username>
            <mat-error *ngIf="!registerForm.get('_username')?.valid && (registerForm.get('_username')?.dirty) || submitted">Introduce un email correcto</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <input placeholder="Contraseña*" matInput formControlName="_password" #_password type="password">
            <mat-error *ngIf="!registerForm.get('_password')?.valid && (registerForm.get('_password')?.dirty) || submitted">Introduce tu password correctamente</mat-error>
        </mat-form-field>
        <!-- <mat-error *ngIf="registerForm.get('_password').hasError('credendialsError')">{{ registerForm.get('_password').getError('credendialsError') }}</mat-error> -->
    </form>
    <button mat-flat-button color="basic" (click)="onSubmit()"  aria-label="register_btn" type="submit" [disabled]="!registerForm?.valid">acceder
    </button>
    <a href="resseting/request">¿Has olvidado tu contraseña?</a>
</div>
<app-toolbar-alerts *ngIf="error != null" [status]="'ko'" [alertMessage]="error"></app-toolbar-alerts>

import { SharedModule } from './shared';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgChartsModule } from 'ng2-charts';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RessetingPassComponent } from './modules/resseting-pass/resseting-pass.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AuthGuard } from './core/services/guards/auth.guard';
import { ErrorInterceptorService } from './core/interceptors/error-interceptor.service';
import { CheckEmailComponent } from './modules/resseting-pass/check-email/check-email.component';
import { ResetFormComponent } from './modules/resseting-pass/reset-form/reset-form.component';
import { JwtInterceptorService } from './core/interceptors/jwt-interceptor.service';
import { UserTypeGuard } from './core/services/guards/userType.guard';
import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { MaintenanceComponent } from './modules/maintenance/maintenance.component';
import { LoginAdminComponent } from './modules/login-admin/login-admin.component';
import { MaintenanceGuard } from './core/services/guards/maintenance.guard';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = `confiasupport.zendesk.com`;
  override lazyLoad: boolean = true;
  callback(zE) { 
    zE('webWidget', 'show' );
  }
}

@NgModule({
  declarations: [
    AppComponent,
    RessetingPassComponent,
    CheckEmailComponent,
    ResetFormComponent,
    MaintenanceComponent,
    LoginAdminComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgChartsModule,
    RouterModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig)
    
  ],
  providers: [
    AuthGuard,
    UserTypeGuard,
    MaintenanceGuard,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptorService,
    multi: true
  },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

<mat-toolbar id="errorMessage" #errorMessage *ngIf="!toolbarHidden && isVisible" 
[ngClass]="{
    'alert_toolbar-ok': status == 'ok',
    'alert_toolbar-ko':status == 'ko',
    'alert_toolbar-warning':status == 'warning',
    'alert_toolbar-info':status == 'info'
}"
[@visibleHidden]=" isVisible? 'visible' : 'hidden'">
    <mat-icon *ngIf="status == 'ok'">check</mat-icon>
    <mat-icon *ngIf="status == 'ko'">error_outline</mat-icon>
    <mat-icon *ngIf="status == 'warning'">warning_amber</mat-icon>
    <mat-icon *ngIf="status == 'info'">info</mat-icon>
    <p>{{alertMessage}}</p>

    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
      <mat-icon (click)="closeToolbar(); toggle()">close</mat-icon>
    </button>
  </mat-toolbar>

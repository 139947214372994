<div class="reg_container">
    <div class="Page_container">
        <img src="../../../../assets/img/logo azul.png" alt="logo_confia">
        <mat-divider></mat-divider>
        <div class="reg_content">
         <!--    <mat-toolbar *ngIf="errorAlert" class="alert_toolbar-ko">
                <mat-icon >error_outline</mat-icon>
                <p>{{errorMessage}}</p>
                <span class="example-spacer"></span>
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
                  <mat-icon (click)="closeToolbar()">close</mat-icon>
                </button>
              </mat-toolbar> -->
                <h2>Cambia tu contraseña</h2>
                <div class="forms_container">
                    <div class="passwordForm-title">
                       <p>INTRODUCIR NUEVA CONTRASEÑA</p>
                    </div>
                    <div class="passwordForm-body">
                        <form class="password_form" [formGroup]="resetPasswordForm" novalidate (ngSubmit)="onSubmit()">
                            <mat-form-field appearance="standard" color="primary">
                                <mat-label color="accent">Contraseña antigua</mat-label>
                                <input matInput formControlName="previousPassword" #password type="password">
                                <mat-error *ngIf="resetPasswordForm.get('previousPassword')?.valid">Introduce un email correcto</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="standard" color="primary">
                                <mat-label color="accent">Nueva contraseña</mat-label>
                                <input matInput formControlName="newPassword" #password type="password">
                                <mat-error *ngIf="!resetPasswordForm.get('newPassword')?.valid && (resetPasswordForm.get('email')?.dirty)">Introduce una contraseña válida</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="standard" color="primary">
                                <mat-label color="accent">Vuelve a escribir la nueva contraseña</mat-label>
                                <input matInput formControlName="repeatNewPassword" #password type="password">
                                <mat-error *ngIf="resetPasswordForm.get('repeatNewPassword')?.value !== resetPasswordForm.get('newPassword')?.value">Las contraseñas tienen que coincidir</mat-error>
                            </mat-form-field>
                        </form>
                        <button mat-stroked-button color="primary" (click)="onSubmit()" aria-label="register_btn" type="submit" [disabled]="!resetPasswordForm?.valid">
                            Enviar
                        </button>
                        
                    </div>
                   
                </div>
        </div>
    </div>
</div>

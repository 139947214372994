<div class="reg_container">
  <div class="Page_container">
<app-header-finance></app-header-finance>
<div class="page-container">
  <div class="img-container">
    <h1 class="title-big">Empieza a incrementar</h1>
    <span class="color-inversor">tus ventas desde ya.</span>
  </div>
  <div class="content-container">   
    <p class="text-item">
      Si estás interesado en financiar tus operaciones a través de
      <strong>CONFIA</strong> ponte en contacto con nosotros escribiendo a
      afiliacion.confia@confia.com.es y describiendo el tipo de negocio que
      tienes y la fecha de comienzo de tu actividad. Uno de nuestros comerciales
      se pondrá en contacto contigo para que veas cómo <strong>CONFIA</strong> se
      adapta a tu negocio.
    </p>   
    <app-contact-form-shared></app-contact-form-shared>
    </div>
  </div>
  </div>
  </div>

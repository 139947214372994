<div class="footer_landing" (window:resize)="onResize($event)" [ngClass]="{
    'backgroundAffiliate':afilliateType && hiddenHeader,
    'backgroundBorrower':borrowerType && hiddenHeader,
    'backgroundLanding': !afilliateType && !borrowerType || !hiddenHeader
}">
    <div class="text_container" *ngIf="visible">
        <a routerLink="contactanos">Contáctanos</a>
        <a routerLink="condiciones-uso">Info Legal</a>
        <a routerLink="mapa-web">Mapa Web</a>
        <a routerLink="diccionario">Diccionario</a>
    </div>
    <div class="footer_icon" [hidden]="hiddenText">
        <p>Entidad de pago:</p>
        <img src="../../../../assets/img/logo-lemonway-white.png" alt="lemonway_logo">
    </div>
</div>


<div mat-dialog-content>
    <div class="spinner_container">
        <p class="spinner_title">Un momento por favor...</p>
        <div class="loadingio-spinner-spin-p4i1zo3hzcf">
            <div class="ldio-2i3seeongzc">
            <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
            </div></div>
        <p>Pago a tu medida con </p>
        <img class="img_logo" src="../../../../assets/img/logo azul.png" alt="logo confia">
    </div>
</div>
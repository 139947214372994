import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-id-conditions',
  templateUrl: './payment-id-conditions.component.html',
  styleUrls: ['./payment-id-conditions.component.scss']
})
export class PaymentIdConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

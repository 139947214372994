import { Component, OnInit } from '@angular/core';
import { ExpansionPanel } from 'src/app/core/models/expansion-panel';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  icon: boolean = false;
  public comoEmpezar: ExpansionPanel []=[
    {
      title:"¿Qué es un afiliado?",
      description: "Un afiliado es una empresa o autónomo que quiere ofrecer a sus clientes la posibilidad de financiar los pagos por los servicios o productos que adquiere el cliente.",
    },
    {
      title:"¿Quién recibe el dinero de la financiación?",
    description:"Una vez que la operación ha sido aprobada es el afiliado quien recibe el dinero de la financiación. Confia cobrará mensualmente al prestatario las mensualidades que haya acordado hasta satisfacer la deuda. Si el afiliado decide asumir el coste de la financiación completo o una parte de este, Confia descontará del importe de la transferencia que deba realizar al afiliado el coste acordado.",
    },
    {
      title:" ¿Tiene algún coste para mi ser afiliado de Confia?",
    description:"Ser afiliado de Confia por sí mismo no tiene ningún coste. Los únicos costes se darían en caso de que se realicen financiaciones cuyo coste decidas asumirlo tu como afiliado.",
    },
    {
      title:"¿Quién está detrás de Confia?",
    description:"Unión Financiera Asturiana S.A.E.F.C en funcionamiento desde 1985.",
    },
    {
      title:"¿Cómo de rápido puede Confia aprobar una financiación a uno de mis clientes?",
    description:"Confia utiliza un sistema automatizado de aprobación. El tiempo medio de aprobación son 24 segundos.",
    },
    {
      title:" ¿Cómo abro una cuenta de afiliado en Confia?",
    description:"Escribe un email a afiliacion.confia@confia.com.es indicando el producto o servicio que ofreces. Una persona de nuestro equipo se pondrá en contacto contigo para asesorarte.",
    }   
    ]
    public solicitudFinanciacion: ExpansionPanel []=[
      {
        title:" ¿Cuándo puedo iniciar una solicitud de financiación para mi cliente?",
        description: "En el momento en que tu cliente haya mostrado interés por financiar el producto o servicio que le hayas ofrecido.",
      },
      {
        title:"¿Cuánto tiempo cuesta generalmente cumplimentar una solicitud de financiación?",
      description:"Generalmente se suele completar una solicitud en 5 minutos.",
      },
      {
        title:"¿Puedo utilizar tanto yo como mi cliente dispositivos móviles para realizar estos pasos?",
      description:"Desde luego. La plataforma de Confia está adaptada para que todo se pueda realizar desde el dispositivo que prefieras: móvil, tablet u ordenador.",
      },
      {
        title:"¿Tiene algún coste iniciar una solicitud de financiación?",
      description:"No tiene absolutamente ningún coste. Puedes realizar todas las solicitudes que estimes oportuno siempre que tu cliente se haya mostrado interesado.",
      },
      {
        title:" ¿Qué cantidad debo poner al realizar una simulación?",
      description:"Debes indicar la cantidad que corresponda al precio del producto o servicio, es decir, el dinero que tu vas a recibir, y que debe aparecer en la factura o recibo que generes para tu cliente. Por ejemplo, si tu cliente va a pagar por dos unidades de un producto, en la cantidad deberás indicar el total de estas dos unidades.",
      },
      {
        title:" ¿La mensualidad que aparece en las diferentes opciones incluye todos los costes?",
      description:"Sí, la mensualidad que aparece debajo de cada opción de meses en el simulador ya incluye todos los costes para el cliente (si los hay).",
      },
      {
        title:"¿Qué sucede cuando inicio una solicitud de financiación?",
      description:"En ese momento, en el email que ha indicado tu cliente le llegará un email con un usuario y contraseña. Si tu cliente está presencialmente puedes cumplimentar su solicitud pero hay ciertos pasos que tendrá que realizar obligatoriamente tu cliente.",
      },
      {
        title:" ¿Puedo modificar alguno de los datos de la financiación una vez iniciada?",
      description:"Si deseas modificar algún dato de la financiación debes dirigirte a nuestro equipo de soporte escribiendo a soporte.confia@confia.com.es.",
      }     
      ]
      public estadoFinanciacion: ExpansionPanel []=[
        {
          title:"¿Qué significan los diferentes estados en que se encuentra una financiación?",
          description: "Indican la situación en la que se encuentra la solicitud: Aprobada, Denegada, Cancelada… o bien pendiente de algún documento o paso intermedio. Recuerda que en cualquier momento en los 29 días siguientes a la fecha de inicio de la solicitud de crédito podrás retomarla justo donde la dejaste… tú o tu cliente.",
        },
        {
          title:" ¿Si una financiación está preaprobada, significa que ya voy a recibir el dinero?",
        description:"No necesariamente. Si bien hay una alta probabilidad de que así sea, todavía necesitamos que el cliente firme los documentos que le pedimos o bien todavía se está completando la financiación.Solo cuando está aprobada la financiación podemos transferir el dinero a nuestros afiliados.",
        },
        {
          title:"¿ ¿Qué pasa si una financiación está denegada?",
        description:"El perfil de solvencia del cliente no nos permite aprobar la operación. En este momento tienes la potestad de contactar con el cliente para ofrecerle otras opciones de pago.",
        },
        {
          title:" ¿Cuánto tiempo tardáis en enviarme el dinero de la financiación una vez que está aprobada?",
        description:"Una vez aprobada una financiación aparecerá una transacción en el listado de transacciones. Esto indica que hay una transferencia pendiente que te llegará en menos de 48 horas.",
        },
        {
          title:"¿Puedo cancelar una financiación?",
        description:"Si deseas cancelar una financiación debes indicárnoslo desde el menú de ayuda que tienes en la barra superior del portal de Confia cuando has entrado con tu usuario.",
        },
        {
          title:"¿Cómo se si ya se me ha abonado una financiación aprobada?",
        description:"En la pestaña de transacciones, cada transacción tiene un estado que te indica si ya se ha abonado dicha transacción o si está pendiente de realizarse la transferencia.",
        },
        {
          title:" ¿Para que sirve la pestaña de enlaces?",
        description:"Desde la pestaña de enlaces (no disponible para todos los afiliados) puedes generar URLs que lleven a una página de Confia especialmente preparada para tu producto/servicio con el precio de dicho producto/servicio. Puedes utilizar esta URL para añadirla en tu sitio web y facilitar a tus clientes que puedan ver el coste de financiar dicho producto/servicio con Confia. Puedes generar tantos enlaces como desees.",
        },   
        ]
        public otrasPreguntas: ExpansionPanel []=[
          {
            title:"¿Qué pasa si tengo muchas solicitudes denegadas?",
            description: "Si observamos un ratio excesivamente alto de solicitudes denegadas nos pondremos en contacto contigo para ayudarte a entender que está pasando.",
          },
          {
            title:" ¿Qué pasa si desde Confia detectamos un ratio excesivamente alto de operaciones problemáticas?",
          description:"Si observamos un ratio excesivamente alto de operaciones problemáticas nos pondremos en contacto contigo para ayudarte a entender que está pasando.",
          }
          ]
  constructor() { }

  ngOnInit(): void {
  }

}

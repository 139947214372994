<div class="page-container">
  <div class="header-main-contain">
    <div class="imgs-contain">
      <img src="../../../../assets/LG-Confia-Color-01.svg" alt="" [routerLink]="'/'"/>
    </div>
    <nav class="header-nav">
      <ul class="nav-list">
        <li
          class="nav-item"
          [routerLink]="'/afiliados'"
          routerLinkActive="activeRoute"
        >
          <a >FINANCIA </a>
        </li>
        <li
          class="nav-item"
          [routerLink]="'/afiliados-porque-financiar'"
          routerLinkActive="activeRoute"
        >
          <a>POR QUÉ FINANCIAR </a>
        </li>
        <li
          class="nav-item"
          [routerLink]="'/afiliados-como-funciona'"
          routerLinkActive="activeRoute"
        >
          <a>CÓMO FUNCIONA </a>
        </li>
        <li
          class="nav-item"
          [routerLink]="'/afiliado-registrate'"
          routerLinkActive="activeRoute"
        >
          <a>REGÍSTRATE </a>
        </li>
        <li
          class="nav-item"
          [routerLink]="'/preguntas-afiliados'"
          routerLinkActive="activeRoute"
        >
          <a>PREGUNTAS </a>
        </li>
      </ul>
    </nav>
    <mat-divider color="primary"></mat-divider>
  </div>
</div>

import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { BorrowerService } from 'src/app/core/services/borrower/borrower.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatusBorrowerService {

  public statusBorrower$: BehaviorSubject<any> = new BehaviorSubject('');
  public sub: any

  constructor( private borrowerService: BorrowerService, public router: Router) {
   
    this.sub = interval(3000).subscribe( () => {
        if(localStorage.getItem('idBorrower')){
          this.borrowerService.checkStatus(localStorage.getItem('idBorrower')).subscribe({
            complete: () => {
              
           },
           error: (err) => {
             /* console.log(err) */
  
           },
           next: (res) => {
              this.emitStatus(res)
            
              /* if(this.router.url == '/afiliado'){
                res['loanStatus'] = ''
                this.emitStatus(res)
                console.log(res)
              }else{
                this.emitStatus(res)
                console.log(res)
              }
              if(!this.authService.checkToken()){
                this.sub.unsubscribe()
                console.log("dessub")
              }  */
            }
          })

        }
         
     
    })
    
   }
  

  public checkStatus(): Observable<any> {
    return this.statusBorrower$.asObservable();
  }
  public emitStatus(estado: any): void {
    this.statusBorrower$.next(estado);
   
  }

}

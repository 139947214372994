import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { HideShowAnimation } from '../../animations/hide-show';

@Component({
  selector: 'app-toolbar-alerts',
  templateUrl: './toolbar-alerts.component.html',
  styleUrls: ['./toolbar-alerts.component.scss'],
  animations:[HideShowAnimation]
 
})
export class ToolbarAlertsComponent implements OnInit {

  @Input() status: string;
  @Input() alertMessage : string;
  public toolbarHidden : boolean = false
  isVisible : boolean 
  constructor() { }

  ngOnInit(): void {
    this.isVisible = true
    this.scrollError();
  }

  closeToolbar(){
    this.toolbarHidden = true

  }

  toggle(){
    this.isVisible = !this.isVisible
  }
  scrollError(){
    setTimeout(() => {
      let el = document.getElementById('errorMessage');
      el?.scrollIntoView({behavior:"smooth"});
    }, 100);   
  } 
}

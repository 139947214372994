<div (window:resize)="onResize($event)">
    <div *ngIf="visible" class="burger_menu">
        <mat-toolbar class="toolbar_close">
            <img src="../../../../assets/img/logoBlancoVector.svg" alt="logo_confia" style="cursor:pointer" routerLink="/" >
            <div>
            <button mat-icon-button (click)="showMenu()" (click)="toggle()" *ngIf="!menu">
                <mat-icon>menu</mat-icon>
            </button>
            <button mat-icon-button (click)="hideMenu()" *ngIf="menu" (click)="toggle()">
                <mat-icon>close</mat-icon>
            </button>
            </div>
        </mat-toolbar>  

         <mat-toolbar  [@openCloseBurgerMenu]="isOpen ? 'top' : 'bottom'" class="toolbar_open">   
            <div>
                <mat-accordion *ngIf="hiddenNav">
                    <mat-expansion-panel (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false" hideToggle>
                      <mat-expansion-panel-header class='my-expansion-panel-header'>
                        <mat-panel-title>
                          QUÉ ES CONFIA
                          <mat-icon *ngIf="!panelOpenState">keyboard_arrow_down</mat-icon>
                          <mat-icon *ngIf="panelOpenState">keyboard_arrow_up</mat-icon>
                        </mat-panel-title>
                      </mat-expansion-panel-header >
                      <p routerLink="que-es-confia" (click)="toggle(); hideMenu()">Qué es confia</p>
                      <p  routerLink="como-funciona" (click)="toggle(); hideMenu()">Cómo funciona</p>
                      <p  routerLink="preguntas-confia" (click)="toggle(); hideMenu()">Preguntas</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenStateSecond = true"
                                         (closed)="panelOpenStateSecond = false" hideToggle>
                      <mat-expansion-panel-header class='my-expansion-panel-header'>
                        <mat-panel-title>
                          FINANCIA A TUS CLIENTES
                          <mat-icon *ngIf="!panelOpenStateSecond">keyboard_arrow_down</mat-icon>
                          <mat-icon *ngIf="panelOpenStateSecond">keyboard_arrow_up</mat-icon>
                        </mat-panel-title>
                      </mat-expansion-panel-header >
                      <p  routerLink="afiliados" (click)="toggle(); hideMenu()">Financia</p>
                      <p  routerLink="afiliados-porque-financiar" (click)="toggle(); hideMenu()">Por qué financiar</p>
                      <p  routerLink="afiliados-como-funciona" (click)="toggle(); hideMenu()">¿Cómo funciona?</p>
                      <p  routerLink="afiliado-registrate" (click)="toggle(); hideMenu()">Regístrate</p>
                      <p  routerLink="preguntas-afiliados" (click)="toggle(); hideMenu()">Preguntas</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenStateThird = true"
                                         (closed)="panelOpenStateThird = false" hideToggle>
                      <mat-expansion-panel-header class='my-expansion-panel-header'>
                        <mat-panel-title>
                          MÁS SOBRE CONFIA
                          <mat-icon *ngIf="!panelOpenStateThird">keyboard_arrow_down</mat-icon>
                          <mat-icon *ngIf="panelOpenStateThird">keyboard_arrow_up</mat-icon>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <p routerLink="contactanos" (click)="toggle(); hideMenu()">Contáctanos</p>
                      <p routerLink="condiciones-uso" (click)="toggle(); hideMenu()">Info legal</p>
                      <p routerLink="mapa-web" (click)="toggle(); hideMenu()">Mapa web</p>
                    </mat-expansion-panel>
                  </mat-accordion>
                <div class="loginNav_container" *ngIf="!hiddenNav">
                    <div>
                       <app-login-form
                    [hiddenText]="true"
                    [hiddenNav]="hiddenNav">
                    </app-login-form> 
                    </div>
                    
                    <div><mat-icon class="close-nav_button" (click)="hideLogin()" >close</mat-icon></div>
                    
                </div>
                <div class="nav_account" *ngIf="hiddenNav">
                    <p class="nav_btn" routerLink="crea-tu-cuenta" (click)="toggle(); hideMenu()">Regístrate</p>
                    <div class="login_btn" (click)="showLogin()">
                        <mat-icon>person</mat-icon>
                        <p>MI CONFIA</p>
                    </div>
                </div>
            </div>
        </mat-toolbar>
    </div>
    <div class="nav" *ngIf="!visible">
        <div class="nav_info" *ngIf="hiddenNav">
            <p class="nav_btn" routerLink="que-es-confia">qué es confia</p>
            <p>|</p>
            <p class="nav_btn" routerLink="financiar" routerLink="afiliados">financia a tus clientes</p>
        </div>
        <div class="loginNav_container" *ngIf="!hiddenNav">
            <button mat-icon-button (click)="hideLogin()">
                <mat-icon>close</mat-icon>
            </button>
            <app-login-form
            [hiddenText]="true">
            </app-login-form>
        </div>
        <div class="nav_account" *ngIf="hiddenNav">
            <p class="nav_btn" routerLink="crea-tu-cuenta">Regístrate</p>
            <p>|</p>
            <div class="login_btn" (click)="showLogin()">
                <mat-icon>person</mat-icon>
                <p>MI CONFIA</p>
            </div>
        </div>
    </div>
</div>



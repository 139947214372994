
<div class="reg_container">
  <div class="Page_container">
    <app-header-finance></app-header-finance>
    <div class="page-container">
      <div class="img-container">
        <h1 class="title-big">Más opciones para tus clientes.</h1>
        <span class="color-inversor"> Más ventas para ti.</span>
      </div>
      
      <div class="content-container">
        <div id="divChart"><canvas id="myChart" width="445" height="321"></canvas></div>
        <div class="text-content">
          <p class="text-item">
            En Confia puedes ofrecer a tus clientes la financiación que más se
            ajusta a su situación personal. De esa forma, ganas clientes y ganas
            ventas.
          </p>
          <p class="text-item">
            Tu decides si el coste lo asume tu cliente o lo asumes tú. De esta forma
            puedes ofrecer incentivos para captar a tus clientes de una forma muy
            sencilla.
          </p>
          <p class="text-item">
            Con unos intereses muy competitivos, Confia es la nueva alternativa para
            obtener financiación de la forma más rápida y flexible gracias a las
            nuevas tecnologías.
          </p>
          <button
            mat-flat-button
            color="primary"
            [routerLink]="'/afiliado-registrate'"
            routerLinkActive="registrate"
          >
            EMPIEZA YA
          </button>
        </div>
      </div>
    </div>
    
  </div>

</div>

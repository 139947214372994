import { UserService } from 'src/app/core/services/user/user.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { VtfService } from 'src/app/core/services/VTF/vtf.service';
import { AffiliateService } from 'src/app/core/services/affiliate/affiliate.service';
import { getFase } from 'src/app/core/extensions/status.extensions';
import { SEOService } from 'src/app/core/services/SEO/seoservice.service';
import { UserDataService } from 'src/app/core/services/user/userData.service';
import { concatMap, of } from 'rxjs';

@Component({
  selector: 'app-login-vtf',
  templateUrl: './login-vtf.component.html',
  styleUrls: ['./login-vtf.component.scss']
})
export class LoginVTFComponent implements OnInit {
  _userId: string;
  _userHash: string;
  public userType: string;
  public affiliatePath: string[];
  public creditList: any[];
  public activeCredit: any;
  public belenderActive: string = ''
  
  constructor(
    private vtfService: AuthService,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private userService: UserService,
    private router: Router,
    private affiliateService: AffiliateService,
    private seoService: SEOService,
    private userDataService: UserDataService
    ) { }

  ngOnInit(): void {
    
    /* this.spinnerService.showLoading(); */
    this.route.paramMap.subscribe((params) => {
      this._userId = params.get("id");
      this._userHash = params.get("hash");
      if(this.router.url.includes('belender')){
        this.belenderActive = '?belender=1'
      }
    })
   
    this.vtfService.getToken(this._userId, this._userHash, this.belenderActive).subscribe(
      res => {
        this.setSession(res.token);
        this.userData();
      },
      err => {
        /* console.log(err) */
      }
    )
  }
  userData() {
    this.userDataService.setUserType('');
    this.userDataService.setNameSession('');
   
    this.userService.getUser(this._userId).pipe(
      concatMap((res) => {
        localStorage.setItem('userType', res.tipo);
        this.userDataService.setUserType(res.tipo);
        localStorage.setItem('userId', this._userId);
        localStorage.setItem('userName', res.firstname);
    
        this.userType = localStorage.getItem('userType');
    
        if (this.userType === 'prestatario') {
          this.userDataService.setNameSession(res.firstname);
          if (res.afiliado) {
            this.affiliatePath = res.afiliado?.split('/');
            const affiliateId = this.affiliatePath?.[this.affiliatePath?.length - 1];
            localStorage.setItem('affiliateId', affiliateId);
    
            return this.userService.getCreditList(this._userId);
          } else {
            this.router.navigate(['/prestatario/inactivo']);
            return of(null); // Emite un valor nulo para que el siguiente operador reciba algo
          }
        } else {
          this.affiliatePath = res.afiliado?.split('/');
          const affiliateId = this.affiliatePath?.[this.affiliatePath?.length - 1];
          localStorage.setItem('affiliateId', affiliateId);
          return of(null); // Emite un valor nulo para que el siguiente operador reciba algo
        }
      }),
      concatMap((creditList) => {
        if (creditList) {
          this.creditList = creditList["hydra:member"];
          this.activeCredit = this.creditList.find(item => getFase(item.estadoCd) == 'FC' || getFase(item.estadoCd) == 'FA'|| getFase(item.estadoCd) == 'FS' || getFase(item.estadoCd) == 'FF' || getFase(item.estadoCd) == 'FE' ||
            item.estadoCd == 'EAM' || item.estadoCd == 'RET' || item.estadoCd == 'FAL');
        }
    
        return this.affiliateService.getAffiliate(localStorage.getItem('affiliateId'));
      })
    ).subscribe((data) => {
      localStorage.setItem('financedRate', data.financedRate);
      if(this.userType != 'prestatario'){
        this.userDataService.setNameSession(data.corporationName); 
      }
      const businessPath = data.datosEmpresa?.split('/');
      const businessId = businessPath?.[businessPath?.length - 1];
      const userPath = data.associatedInvestor?.split('/');
      const userId = userPath?.[userPath?.length - 1];
      const salesAgentPath = data.zankSalesAgent?.split('/');
      const agentId = salesAgentPath?.[salesAgentPath?.length - 1];
      const reportsEnabled = data.reports
      const linksEnbled = data.links
      const simuladorEnabled = data.simulador
      const usuariosEnabled = data.usuarios
      const cobrosEnabled = data.cobrosEnabled
      localStorage.setItem('businessId', businessId);
      localStorage.setItem('estadoCd', data.estadoCd);
      localStorage.setItem('corporationName', data.corporationName);
      localStorage.setItem('datosEmpresa', data.datosEmpresa);
      localStorage.setItem('associatedInvestor', userId);
      localStorage.setItem('salesAgentId', agentId);
      localStorage.setItem('inversores', data.usuarios)
      localStorage.setItem('cobrosEnabled', cobrosEnabled);
      localStorage.setItem('reports', reportsEnabled);
      localStorage.setItem('links', linksEnbled);
      localStorage.setItem('simulador', simuladorEnabled);
      localStorage.setItem('usuarios', usuariosEnabled);
      if (this.userType == 'prestatario') {
        if (this.activeCredit) {
          this.router.navigate(['/prestatario'], { queryParams: { id: this.activeCredit.id } });
        } else {
          this.router.navigate(['/prestatario/inactivo']);
        }
      } else {
        this.router.navigate(['/afiliado']);
      }
    }, err => {
      /* console.log(err) */
    });
  }

  setSession(token: string) {
    if (token != '')
    this.vtfService.setSessionLogged(token);
  }

  logout() {
    return this.vtfService.logout();
  }

}
